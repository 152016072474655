import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
    func
} from 'prop-types';
import { Row, Col, DatePicker, Table, Tag, Progress, Avatar, Button, Input, Dropdown, Menu, Select, Form, Tooltip, Modal, notification } from "antd";
import { PlusOutlined, SearchOutlined, MoreOutlined, EditOutlined } from "@ant-design/icons";
import { submitProject, updateProject } from '../../redux/actions/projectAction';
import moment from 'moment';
import CreateProject from '../../components/createProject';
// import "./ProjectsDashboard.scss";
const { Option } = Select;
const { Search } = Input;
const data = [
    {
        clientId: "1",
        projectName: "Southwest Airlines",
        location: "Southwest Airlines Co.",
        projectStatus: "1",
        manager: "A Need For Upgrades",
        startDate: "Local School Receives New Grant",
        endDate: 'xyz',
        members: ["A", "B", "C"],
        progress: 100,
    },
];

const statusColors = {
    "Completed": "green",
    "Active": "orange",
    "OnHold": "blue",
    "Initiated": "geekblue",
    "Facility": "pink"
};

const getStatusString = (status) => {
    switch (status) {
        case 1:
            return 'Initiated';
        case 2:
            return 'Active';
        case 3:
            return 'OnHold';
        case 4:
            return 'Completed';
        case 5:
            return 'Facility';
        default:
            return 'Unknown';
    }
};

const ProjectDashboard = (props) => {
    const { onSubmitProject, onUpdateProject, reloadData } = props;
    const { setSelectedProj, selectedProject, projectList, clientList, setIsShowProjectData, onProjectClick } = props;
    const [isEditStartDate, setIsEditStartDate] = useState(false);
    const [isEditEndDate, setIsEditEndDate] = useState(false);
    const [isEditprojectStatus, setIsEditprojectStatus] = useState(false);
    const [startDateInput, setStartDateInput] = useState('');
    const [endDateInput, setEndDateInput] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dayShiftHour, setDayShiftHour] = useState('');
    const [nightShiftHour, setNightShiftHour] = useState('');
    const [dayShiftInputHour, setDayShiftInputHour] = useState('');
    const [nightShiftInputHour, setNightShiftInputHour] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [masterProject, setMasterProject] = useState([]);
    const [searchText, onSearch] = useState('');
    const [text, setText] = useState('');
    const [projectId, setProjectId] = useState(0);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [isEditDefaultHourModalVisible, setIsEditDefaultHourModalVisible] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [projectStatus, setProjectStatus] = useState('');
    const [projectForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filterStatus, setFilterStatus] = useState(0);
    const [completedProject, setCompletedProject] = useState(0);
    const [facilityProject, setFacilityProject] = useState(0);
    const [activeProject, setActiveProject] = useState(0);
    const [initiatedProject, setInitiatedProject] = useState(0);
    const [onHoldProject, setOnHoldProject] = useState(0);
    const [updateProjectId, setUpdateProjectId] = useState(0);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [projectName, setProjectName] = useState('');
    const [isGuestUser, setIsGuestUser] = useState(false);

    const getSelectedProjectId = (id) => {
        if (masterProject.length > 0) {
            sessionStorage.setItem('activeId', id);
            var activeId = sessionStorage.getItem('activeId');
            setProjectId(parseInt(activeId));
            masterProject.map((data) => {
                if (data.id === id) {
                    setSelectedProj(data);
                    setStartDate(moment(data.startDate));
                    setStartDateInput(moment(data.startDate));
                    setDayShiftHour(data?.config?.technicalLogDefaultShiftHours?.day);
                    setNightShiftHour(data?.config?.technicalLogDefaultShiftHours?.night);
                    setDayShiftInputHour(data?.config?.technicalLogDefaultShiftHours?.day);
                    setNightShiftInputHour(data?.config?.technicalLogDefaultShiftHours?.night);
                    setSelectedStatus(data.projectStatus);
                    setProjectStatus(data.projectStatus);
                    if (data.endDate) {
                        setEndDate(moment(data.endDate));
                        setEndDateInput(moment(data.endDate));
                    } else {
                        setEndDate(null);
                        setEndDateInput(null);
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (projectList?.length !== 0) {
            setMasterProject(projectList);
            let completedCount = 0;
            let activeCount = 0;
            let facilityCount = 0;
            let initiatedCount = 0;
            let onHoldCount = 0;
            projectList.forEach(item => {
                switch (item.projectStatus) {
                    case 5:
                        facilityCount++;
                        break;
                    case 4:
                        completedCount++;
                        break;
                    case 2:
                        activeCount++;
                        break;
                    case 1:
                        initiatedCount++;
                        break;
                    default:
                        onHoldCount++;
                        break;
                }
            });
            setCompletedProject(completedCount);
            setFacilityProject(facilityCount);
            setActiveProject(activeCount);
            setInitiatedProject(initiatedCount);
            setOnHoldProject(onHoldCount);
            //
            setDataSource(projectList);
            var activeId = sessionStorage.getItem('activeId');
            if (activeId) {
                if (projectList.length > 0) {
                    var position = projectList.find(u => u.id === parseInt(activeId));
                    if (position)
                        getSelectedProjectId(position.id);
                }
            } else {
                if (projectList.length > 0 && projectList[0] && projectList[0].projectId) {
                    getSelectedProjectId(projectList[0].id);
                }
            }
        }
    }, [projectList]);

    useEffect(() => {
        if (projectList?.length !== 0) {
            if (filterStatus > 0) {
                const filtered = projectList.filter(item => item.projectStatus === filterStatus);
                setDataSource(filtered);
            } else {
                setDataSource(projectList);
            }
        }
    }, [filterStatus]);

    const showModal = (record) => {
        setUpdateProjectId(record.id)
        if (record?.id) {
            projectForm.setFieldsValue({
                clientName: record?.clientName || '',
                manager: record?.manager || '',
                startDate: record?.startDate ? moment(record.startDate, "MM-DD-YYYY") : null,
                endDate: record?.endDate ? moment(record.endDate, "MM-DD-YYYY") : null,
                projectStatus: record?.projectStatus,
                completedWelds: '-',
                technicianLog: `${record?.config?.technicalLogDefaultShiftHours?.day || '0'} Hour / ${record?.config?.technicalLogDefaultShiftHours?.night || '0'} Hour`
            });
            setDayShiftInputHour(record?.config?.technicalLogDefaultShiftHours?.day)
            setNightShiftInputHour(record?.config?.technicalLogDefaultShiftHours?.night)
            setProjectName(record?.projectName);
            setIsModalVisible(true);
        }
    };


    const handleCancel = () => {
        setIsModalVisible(false);
        projectForm.resetFields();
    };

    const handleSave = async () => {
        try {
            // Validate form fields
            await projectForm.validateFields();
            const formValues = projectForm.getFieldsValue();
            const updateData = {
                startDate: moment(formValues.startDate).format('MM-DD-YYYY'),
                endDate: moment(formValues.endDate).format('MM-DD-YYYY'),
                projectStatus: formValues.projectStatus,
                config: {
                    technicalLogDefaultShiftHours: {
                        day: dayShiftInputHour.toString(),
                        night: nightShiftInputHour.toString()
                    }
                }
            };

            // If projectId exists, update the project
            if (updateProjectId) {
                onUpdateProject({ data: updateData, id: updateProjectId });
                setIsModalVisible(false);
            } else {
                notification.warn("Project ID is missing, can't update project.");
            }
        } catch (error) {
            console.error("Validation failed:", error);
            notification.error("Please correct the form errors before saving.");
        }
    };


    const handleProjectClick = (event, data) => {
        if (event === 'project-clicked') {
            onProjectClick();
        }
    };

    const searchData = (e) => {
        const searchText = e.target.value.trim().toLowerCase();

        if (searchText.length > 0) {
            const filteredData = masterProject.filter((value) =>
                value.projectName.toLowerCase().includes(searchText) ||
                value.projectId.toLowerCase().includes(searchText) ||
                value.location.toLowerCase().includes(searchText) ||
                value.manager.toLowerCase().includes(searchText) ||
                value.clientName.toLowerCase().includes(searchText)
            );

            if (filteredData.length > 0) {
                setDataSource(filteredData);
                setSelectedProj([]);
                setStartDate(null);
                setEndDate(null);
                setStartDateInput(null);
                setEndDateInput(null);
                setSelectedStatus('');
                setProjectStatus('');
                setSearchNotFound(false);
            } else {
                notification.warning({
                    message: `No Project Found with "${searchText}"`
                });
                setSearchNotFound(true);
                setText('');
                onSearch('');
            }
        } else {
            // Reset state when search text is cleared
            setDataSource(masterProject);
            setSelectedProj([]);
            setStartDate(null);
            setEndDate(null);
            setStartDateInput(null);
            setEndDateInput(null);
            setSelectedStatus('');
            setProjectStatus('');
            setSearchNotFound(false);
        }
    };


    const onSaveProject = async (data, client) => {
        if (data.endDate) {
            data.endDate = moment(data.endDate).format('MM-DD-YYYY');
        } else {
            data.endDate = null;
        }
        var obj = {
            "projectId": data.projectId,
            "projectName": data.projectName,
            "manager": data.manager,
            "startDate": moment(data.startDate).format('MM-DD-YYYY'),
            "endDate": data.endDate,
            "contractId": data.contractId,
            "pihRefId": data.pihRefId,
            "contractor": data.contractor,
            "location": data.location,
            "clientId": client.id,
            "config": {
                "technicalLogDefaultShiftHours": {
                    "day": data.dayShiftHour.toString(),
                    "night": data.nightShiftHour.toString()
                }
            }
        };
        await onSubmitProject({ data: obj, clientName: data.clientName });
    };

    const handleStatusChange = value => {
        setSelectedStatus(value);
    };

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "projectName",
            key: "projectName",
            ellipsis: {
                showTitle: true
            },
            width: "15%",
            render: (text, record) => {
                const status = getStatusString(record.projectStatus);
                let avatarColor;

                switch (status) {
                    case 'Completed':
                        avatarColor = 'green';
                        break;
                    case 'Active':
                        avatarColor = 'orange';
                        break;
                    case 'OnHold':
                        avatarColor = '#00A6ED';
                        break;
                    case 'Initiated':
                        avatarColor = '#1F305E';
                        break;
                    case 'Facility':
                        avatarColor = 'pink';
                        break;
                    default:
                        avatarColor = 'default';
                }

                return (
                    <div>
                        <Avatar style={{ marginRight: 8, backgroundColor: avatarColor }}>{text[0]}</Avatar>
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{truncateTableData(text)}</span>
                    </div>
                );
            },
            filters: dataSource.map((item) => ({
                text: item.projectName,
                value: item.projectName,
            })),
            filterSearch: true,
            onFilter: (value, record) => record.projectName.startsWith(value),
            filteredValue: filteredInfo.projectName || null,
            onFilter: (value, record) => record.projectName.includes(value),
            sorter: (a, b) => a.projectName.localeCompare(b.projectName),
            sortOrder: sortedInfo.columnKey === 'projectName' ? sortedInfo.order : null,
        },
        {
            title: "Status",
            dataIndex: "projectStatus",
            key: "projectStatus",
            render: (status, record) => {
                status = getStatusString(status);
                return <div><Tag className="project-status" color={statusColors[status]}>{status}</Tag></div>;
            }
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            render: (text, record) => <div ><strong>{text}</strong></div>,
            filters: Array.from(new Set(dataSource.map(item => item.location)))
                .map(location => ({
                    text: location,
                    value: location,
            })),
            filterSearch: true,
            onFilter: (value, record) => record.location.startsWith(value),
            filteredValue: filteredInfo.location || null,
            onFilter: (value, record) => record.location.includes(value),
            sorter: (a, b) => a.location.localeCompare(b.location),
            sortOrder: sortedInfo.columnKey === 'location' ? sortedInfo.order : null,
        },
        {
            title: "Project Id",
            dataIndex: "projectId",
            key: "projectId",
            render: (text, record) => <div ><strong>{text}</strong></div>,
            filters: dataSource.map((item) => ({
                text: item.projectId,
                value: item.projectId,
            })),
            filterSearch: true,
            onFilter: (value, record) => record.projectId.startsWith(value),
            filteredValue: filteredInfo.projectId || null,
            onFilter: (value, record) => record.projectId.includes(value),
            sorter: (a, b) => a.projectId.localeCompare(b.projectId),
            sortOrder: sortedInfo.columnKey === 'projectId' ? sortedInfo.order : null,
        },
        {
            title: "Client Name",
            dataIndex: "clientName",
            key: "clientName",
            render: (text, record) => (
                <div >
                    <strong>{text}</strong>
                </div>
            ),
            filters: Array.from(new Set(dataSource.map(item => item.clientName)))
                .map(clientName => ({
                    text: clientName,
                    value: clientName,
            })),
            filterSearch: true,
            onFilter: (value, record) => record.clientName.startsWith(value),
            filteredValue: filteredInfo.clientName || null,
            onFilter: (value, record) => record.clientName.includes(value),
            sorter: (a, b) => a.clientName.localeCompare(b.clientName),
            sortOrder: sortedInfo.columnKey === 'clientName' ? sortedInfo.order : null,
        },
        {
            title: "Manager",
            dataIndex: "manager",
            key: "manager",
            render: (text, record) => (
                <div >
                    <strong>{text}</strong>
                </div>
            ),
            filters: Array.from(new Set(dataSource.map(item => item.manager)))
                .map(manager => ({
                    text: manager,
                    value: manager,
            })),
            filterSearch: true,
            onFilter: (value, record) => record.manager.startsWith(value),
            filteredValue: filteredInfo.manager || null,
            onFilter: (value, record) => record.manager.includes(value),
            sorter: (a, b) => a.manager.localeCompare(b.manager),
            sortOrder: sortedInfo.columnKey === 'manager' ? sortedInfo.order : null,
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
            key: "startDate",
            render: (text, record) => (
                <div >
                    <strong> {text ? moment(text).format('DD MMM YYYY') : '-'}</strong>
                </div>
            ),
            filters: Array.from(new Set(dataSource.map(item => item.startDate)))
                .map(startDate => ({
                    text: startDate,
                    value: startDate,
                })),
            filterSearch: true,
            onFilter: (value, record) => {
                return record.startDate === value;
            },
            filteredValue: filteredInfo.startDate || null,
        },
        {
            title: "End Date",
            dataIndex: "endDate",
            key: "endDate",
            render: (text) => (
                <div>
                    <strong>{text ? moment(text).format('DD MMM YYYY') : '-'}</strong>
                </div>
            ),
            filters: Array.from(new Set(dataSource.map(item => item.endDate)))
                .map(endDate => ({
                    text: endDate,
                    value: endDate,
                })),
            filterSearch: true,
            onFilter: (value, record) => {
                return record.endDate === value;
            },
            filteredValue: filteredInfo.endDate || null,
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            width: '90px',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }} onClick={() => {
                                    // getSelectedProjectId(record.id);
                                    showModal(record)
                                }} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        }
    ];

    const handleRowClick = (record) => {
        getSelectedProjectId(record.id);
        handleProjectClick('project-clicked');
    };

    const onRow = (record) => {
        return {
            onClick: (event) => {
                // Check if the click target is within the "Actions" column
                if (!event.target.closest('.ant-table-cell:last-child')) {
                    handleRowClick(record);
                }
            },
        };
    };

    return (
        <div className="projects-dashboard">
            <div className="filters">
                <div className="d-flex project-search">
                    <div className="peoject-header">
                        <h2>Projects</h2>
                    </div>
                    <div className="search">
                        <Input placeholder="Search" onChange={searchData} />
                    </div>
                </div>
                <div className="project-stats">
                    <div className={`stat-item ${filterStatus === 0 ? 'active' : ''}`} onClick={() => setFilterStatus(0)}>
                        <span className="stat-icon">📊</span>
                        <span className="stat-label">Total Projects:</span>
                        <span className="stat-value total-stat">{projectList.length}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 2 ? 'active' : ''}`} onClick={() => setFilterStatus(2)}>
                        <span className="stat-icon" style={{ color: 'orange' }}>🔥</span>
                        <span className="stat-label">Active:</span>
                        <span className="stat-value" style={{ color: 'orange' }}>{activeProject}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 1 ? 'active' : ''}`} onClick={() => setFilterStatus(1)}>
                        <span className="stat-icon" style={{ color: 'blue' }}>🚀</span>
                        <span className="stat-label">Initiated:</span>
                        <span className="stat-value" style={{ color: '#0d47a1' }}>{initiatedProject}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 5 ? 'active' : ''}`} onClick={() => setFilterStatus(5)}>
                        <span className="stat-icon" style={{ color: '#708090' }}>🏢</span>
                        <span className="stat-label">Facility:</span>
                        <span className="stat-value" style={{ color: 'pink' }}>{facilityProject}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 3 ? 'active' : ''}`} onClick={() => setFilterStatus(3)}>
                        <span className="stat-icon" style={{ color: '#00E3FF' }}>⏸️</span>
                        <span className="stat-label">On Hold:</span>
                        <span className="stat-value" style={{ color: '#00A6ED' }}>{onHoldProject}</span>
                    </div>
                    <div className={`stat-item ${filterStatus === 4 ? 'active' : ''}`} onClick={() => setFilterStatus(4)}>
                        <span className="stat-icon" style={{ color: 'green' }}>✅</span>
                        <span className="stat-label">Completed:</span>
                        <span className="stat-value" style={{ color: '#00D26A' }}>{completedProject}</span>
                    </div>
                    <div>
                        <CreateProject
                            projectForm={projectForm}
                            onSaveProject={onSaveProject}
                            clientList={clientList}
                        />
                    </div>
                </div>
            </div>

            <Table
                columns={columns}
                dataSource={dataSource}
                onRow={onRow}
                className="dashBoard-table"
                scroll={{ y: 'calc(100vh - 170px)' }}
                // pagination={dataSource.length > 10 ? true : false}
                pagination={false}
                onChange={handleChange}
            />

            <Modal
                width='50vw'
                className="pipeline-modal"
                title={`Project :- ${projectName || ''}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSave}>
                        Save
                    </Button>,
                ]}
            >
                <Form
                    form={projectForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Client"
                                name="clientName"
                            >
                                <Input
                                    placeholder="Enter Client Name"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Manager"
                                name="manager"
                            >
                                <Input
                                    placeholder="Enter Manager Name"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="date-picker"
                                label="Start Date"
                                name="startDate"
                            >
                                <DatePicker
                                    allowClear={false}
                                    format="DD-MM-YYYY"
                                    defaultValue={startDateInput}
                                    onChange={(date) => setStartDateInput(date)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="date-picker"
                                label="End Date"
                                name="endDate"
                            >
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    allowClear={false}
                                    defaultValue={endDateInput}
                                    onChange={(date) => setEndDateInput(date)}
                                    disabledDate={(current) =>
                                        startDateInput && current < moment(startDateInput, 'DD-MM-YYYY')
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Project Status"
                                name="projectStatus"
                            >
                                <Select
                                    className='client-select'
                                    value={selectedStatus}
                                    onChange={handleStatusChange}
                                >
                                    <Option value={1}>Initiated</Option>
                                    <Option value={2}>Active</Option>
                                    <Option value={3}>On Hold</Option>
                                    <Option value={4}>Completed</Option>
                                    <Option value={5}>Facility</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Completed Welds"
                                name="completedWelds"
                                initialValue="-"
                            >
                                <Input
                                    placeholder="Completed Welds"
                                    size="large"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Technician Log Default Day/Night Shift"
                                name="technicianLog"
                            >
                                <Input
                                    value={`${dayShiftInputHour} Hour / ${nightShiftInputHour} Hour`}
                                    size="large"
                                    onChange={(e) => {
                                        const [day, night] = e.target.value.split(' / ').map(val => parseInt(val));
                                        setDayShiftInputHour(day);
                                        setNightShiftInputHour(night);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
ProjectDashboard.propTypes = {
    onSubmitProject: func.isRequired,
    onUpdateProject: func.isRequired
};

const mapDispatchToProps = {
    onSubmitProject: submitProject,
    onUpdateProject: updateProject,
};

export default connect(null, mapDispatchToProps)(ProjectDashboard);
